import React from 'react';
import { ReactComponent as CheckIcon } from 'images/green-check-rounded.svg';
import { ReactComponent as WarningIcon } from 'images/warning-icon.svg';
import DebtRow from 'components/FinancialCheckup/YourFinances/Debts/DebtRow';
import { formatMonetaryAmount } from 'utils/formatMonetaryAmount';

import styles from './DebtCategories.module.scss';

interface DebtCategoriesProps {
  debtItems: any;
  totalDebt: number;
  title: string;
  icon?: 'warning' | 'check';
  showTotalDebt?: boolean;
}

const DebtCategories = ({
  title,
  debtItems,
  totalDebt,
  icon = 'check',
  showTotalDebt = false,
}: DebtCategoriesProps) => (
  <div className={styles.innerContainer}>
    {showTotalDebt && (
      <p className={styles.totalDebtLabel}>
        <span>Your Total Debt is:</span>
        <span className={styles.totalDebtAmount}>{formatMonetaryAmount(totalDebt)}</span>
      </p>
    )}
    <div className={styles.header}>
      <p className={styles.sectionTitle}>{title}</p>
      {icon === 'check' ? <CheckIcon className={styles.icon} /> : <WarningIcon className={styles.icon} />}
    </div>

    {debtItems.map((debt: any, index: any) => (
      <DebtRow index={index} debt={debt} totalBalance={totalDebt} key={debt.name.trim()} />
    ))}
  </div>
);

export default DebtCategories;
