import React from 'react';

import ActionItemCard from './ActionItemCard/ActionItemCard';
import { ActionItem } from './useActionItems';

import styles from './ActionItems.module.scss';

interface ActionItemsProps {
  items: ActionItem[];
}

const ActionItems = ({ items }: ActionItemsProps) => (
  <div className={styles.container}>
    <p className={styles.title}>Action Items</p>

    <div className={styles.actionItems}>
      {items
        .filter((item) => item.visible)
        .map((item: any) => (
          <ActionItemCard key={item.id} title={item.name} icon={item.icon} route={item.route}>
            <p className={styles.description}>{item.description}</p>
          </ActionItemCard>
        ))}
    </div>
  </div>
);

export default ActionItems;
