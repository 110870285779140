import React from 'react';

import { ReactComponent as Chevron } from 'images/chevron.svg';
import { RoutePath } from 'enums/Routes';
import { useNavigate } from 'hooks/useNavigate';

import styles from './ActionItemCard.module.scss';

interface ActionItemCardProps {
  title: string;
  icon: any;
  children: JSX.Element;
  route: RoutePath;
}

const ActionItemCard = ({ title, icon: Icon, children, route }: ActionItemCardProps) => {
  const navigate = useNavigate();

  return (
    <div className={styles.container}>
      <div className={styles.header} onClick={() => navigate(route)}>
        <Icon className={styles.icon} />
        <p className={styles.title}>{title}</p>
        <Chevron className={styles.arrow} />
      </div>

      <div className={styles.content}>{children}</div>
    </div>
  );
};

export default ActionItemCard;
