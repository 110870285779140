import React from 'react';
import clsx from 'clsx';

import { useFinancialCheckupData } from 'components/FinancialCheckup/YourFinances/yourFinancesUtils';

import DebtCategories from './DebtCategories';

import styles from './Debts.module.scss';

interface DebtsProps {
  joinCategories?: boolean;
  showTotalDebt?: boolean;
}

const Debts = ({ joinCategories = false, showTotalDebt = false }: DebtsProps) => {
  const { keepItDebts, payOffDebts, shouldOfferPlanneryLoan, totalDebt } = useFinancialCheckupData();

  const overchargedTitle = shouldOfferPlanneryLoan ? 'You’re being overcharged!' : 'Consider paying these off ASAP';

  return (
    <div
      className={clsx({
        [styles.join]: joinCategories,
      })}
    >
      {keepItDebts.length > 0 && (
        <DebtCategories
          title="These categories look OK"
          debtItems={keepItDebts}
          totalDebt={totalDebt}
          showTotalDebt={showTotalDebt}
        />
      )}

      <DebtCategories icon="warning" title={overchargedTitle} debtItems={payOffDebts} totalDebt={totalDebt} />
    </div>
  );
};

export default Debts;
